import { createAction } from 'redux-actions';
import { LOGIN_USER, LOGOUT_USER, SET_HIGH_CONTRAST, LOGIN_USER_ERROR } from '../constants/ActionTypes';
import { selectLang } from './OrderActions';
import Odoo from '../utils/Odoo';

export const logUserOut = createAction(LOGOUT_USER);

export function setHighContrast(high_contrast) {
  return (dispatch) => {
    dispatch({
      type: SET_HIGH_CONTRAST,
      payload: high_contrast,
    });
  };
}

export function logUserIn(token) {
  return (dispatch, getState) => {
    const urlParams = new URLSearchParams(window.location.search + window.location.hash);
    let lang = urlParams.get('lang');

    if (!lang) {
      lang = getState().auth.lang; 
    }

    const params = {
      client_token: token,
      lang: lang,
    };

    Odoo.login('order', params)
      .then((session) => {
        dispatch(selectLang(lang));

        dispatch({
          type: LOGIN_USER,
          payload: { ...session },
        });
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_USER_ERROR,
          message: error.message,
        });
      });
  };
}
