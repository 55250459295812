import { LOGIN_USER, LOGOUT_USER, LOGIN_USER_ERROR, SET_LANG} from '../constants/ActionTypes'
import { locales } from '../components/App'

const resetState = {
  invalid: false,
  lang: false,
  high_contrast: false,
  session: {
    token: false
  }
}

function getLang() {
  const lang = navigator.language || 'pl-PL';
  setLang(lang);
  return lang;
}

function setLang(lang) {
  localStorage.setItem('lang', lang);
}

function setHighContrast(high_contrast){
  localStorage.setItem('high_contrast', high_contrast)
}

function getInitialState(){
  return {
    ...resetState,
    lang: getLang()
  }
}

export default function auth(state = getInitialState(), action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state,
        ...getLang(),
        session: {
          'token': action.payload.token
        },
        invalid: false,
      }
    case LOGOUT_USER:
      return resetState
    case LOGIN_USER_ERROR:
      return { ...state,
        invalid: true
      }
    case SET_LANG:
      setLang(action.payload)
      return { ...state,
        lang: action.payload
      }
    default:
      return state
  }
}
